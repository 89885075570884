@font-face {
  font-family: 'customer-icons';
  src:  url('./customer-icons.eot?wgjoow');
  src:  url('./customer-icons.eot?wgjoow#iefix') format('embedded-opentype'),
    url('./customer-icons.ttf?wgjoow') format('truetype'),
    url('./customer-icons.woff?wgjoow') format('woff'),
    url('./customer-icons.svg?wgjoow#customer-icons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'customer-icons' !important;
  /* speak: never; */
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-d-printer:before {
  content: "\e900";
}
.icon-arrow-charge:before {
  content: "\e901";
}
.icon-arrow-pay:before {
  content: "\e902";
}
.icon-attachfiles:before {
  content: "\e903";
}
.icon-attachment-files-icon:before {
  content: "\e904";
}
.icon-back:before {
  content: "\e905";
}
.icon-bin:before {
  content: "\e906";
}
.icon-bold-plus:before {
  content: "\e907";
}
.icon-cancel:before {
  content: "\e908";
}
.icon-cancel-button:before {
  content: "\e909";
}
.icon-checkbox-icon:before {
  content: "\e90a";
}
.icon-close:before {
  content: "\e90b";
}
.icon-credit-card:before {
  content: "\e90c";
}
.icon-cube:before {
  content: "\e90d";
}
.icon-discount-price:before {
  content: "\e90e";
}
.icon-download:before {
  content: "\e90f";
}
.icon-error:before {
  content: "\e910";
}
.icon-expand-arrow:before {
  content: "\e911";
}
.icon-eye:before {
  content: "\e912";
}
.icon-grid-veiw:before {
  content: "\e913";
}
.icon-info:before {
  content: "\e914";
}
.icon-list:before {
  content: "\e915";
}
.icon-list-veiw:before {
  content: "\e916";
}
.icon-lock:before {
  content: "\e917";
}
.icon-logout:before {
  content: "\e918";
}
.icon-materials:before {
  content: "\e919";
}
.icon-message-icon:before {
  content: "\e91a";
}
.icon-minus:before {
  content: "\e91b";
}
.icon-moon:before {
  content: "\e91c";
}
.icon-more:before {
  content: "\e91d";
}
.icon-next:before {
  content: "\e91e";
}
.icon-no-location:before {
  content: "\e91f";
}
.icon-orders:before {
  content: "\e920";
}
.icon-orders-list:before {
  content: "\e921";
}
.icon-package:before {
  content: "\e922";
}
.icon-package-close:before {
  content: "\e923";
}
.icon-pen:before {
  content: "\e924";
}
.icon-plus:before {
  content: "\e925";
}
.icon-sceptic:before {
  content: "\e926";
}
.icon-second-download:before {
  content: "\e927";
}
.icon-setting:before {
  content: "\e928";
}
.icon-setting-lines:before {
  content: "\e929";
}
.icon-shipping:before {
  content: "\e92a";
}
.icon-shopping-bag-empty:before {
  content: "\e92b";
}
.icon-shopping-bag-full:before {
  content: "\e92c";
}
.icon-smile:before {
  content: "\e92d";
}
.icon-success:before {
  content: "\e92e";
}
.icon-triangle:before {
  content: "\e92f";
}
.icon-upload:before {
  content: "\e930";
}
.icon-verified:before {
  content: "\e931";
}
.icon-wallet:before {
  content: "\e932";
}
.icon-warnning:before {
  content: "\e933";
}
